//Icons

//Styles
import "./documents.scss";

//Libraries
import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Customer } from "../../../../models/customer.model";
import { DocumentExtensions } from "../../../../enums/DocumentExtensions.type";
import Modal from "antd/lib/modal/Modal";
import { getFileExtension } from "../../../../shared/utils/getFileExtension";
import { FamilyMember } from "../../../../models/familyMember.model";
import { removeUnderscore } from "../../../../shared/utils/removeUnderscore";
import AppLoader from "../../../../shared/components/AppLoader";
import { BusinessDetail } from "../../../../models/businessDetail.model";
import { LoanType } from "../../../../enums/loanType.type";
import { CloseCircleOutlined, DownOutlined, RightOutlined } from "@ant-design/icons";
import CustomDocument from "../../../../shared/components/CustomDocument";
import uploadIcon from "../../../../Assets/images/upload.png";
import { DocumentCategory } from "../../../../enums/documents.type";
import { DocumentType } from "../../../../enums/documentType.type";
import AttachmentUpload from "../../../../shared/components/AttachmentUpload";
import { Form, Formik, FormikHelpers } from "formik";
import { Loan } from "../../../../models/loan.model";
import { modDocumentValidation } from "../../../MODDocuments/MODUploadForm/modFormValidation";
import CustomModal from "../../../../shared/components/CustomModal";
import { filterModLoanDocuments, getModLoanDocuments } from "../../../../shared/utils/filterModDocs";
import { DocumentUpload } from "../../../../models/fileAttachment.model";
import { modLoansTypes } from "../../../../enums/modLoans.type";
import { ModService } from "../../../../services/MODOfficer/modofficer.service";
import { getThumbnailByLabel } from "../../../../shared/utils/getThumbnailByLabel";

interface DocumentsProps {
  customer?: Customer;
  hasCard?: boolean;
  coBorrower?: FamilyMember;
  loanType?: string;
  additionalDetails?: BusinessDetail;
  nominee?: FamilyMember;
  loan?: Loan
  initializeLoan: () => void;
  initializeCustomer?: () => void
}

const Documents = (props: DocumentsProps) => {
  const {
    customer,
    hasCard = true,
    coBorrower,
    loanType,
    additionalDetails,
    nominee,
    loan,
    initializeLoan,
    initializeCustomer
  } = props;

  const [docModelOpen, setDocModelOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [selectedFile, ] = useState("");
  const isModLoan = loan?.securedType === modLoansTypes.MOD_SECURED || loan?.securedType === modLoansTypes.EQUAITABLE_MORTAGE || loan?.securedType === modLoansTypes.UNSECURED
  const toggleDocModal = () => setDocModelOpen((visible) => !visible);
  const { createDocument } = ModService();
  const isImage = (endpoint: string) =>
    getFileExtension(endpoint) === DocumentExtensions.PDF ? false : true;

  const [currentDocument, setCurrentDocument] = useState<DocumentCategory>();

  useEffect(() => {
    setCurrentDocument(DocumentCategory.BORROWER);
  }, []);

  const handleUploadDocumentModal = async (values: DocumentUpload, formikHelpers: FormikHelpers<DocumentUpload>) => {
    if (values?.frontSideImageId) {
      await createDocument(
        {
          frontSideImageId: values.frontSideImageId,
          documentType: DocumentType.OTHER_SECURITY,
        },
        Number(loan?.id) || 0
      );
      setModal(false)
      if (initializeCustomer)
      initializeCustomer()
      initializeLoan()
    }
  };

  const handleDocumentVisibility = (doc: DocumentCategory) => {
    !(doc === currentDocument) &&
      setCurrentDocument(doc === currentDocument ? undefined : doc);
  };

  const isPending = loan?.modStatus === modLoansTypes.PENDING
  const loanImages = [
    {
      label: "Agri Allied",
      key: LoanType.AGRI_ALLIED,
      value: additionalDetails?.pictureUrl,
    },
    {
      label: "Agriculture ",
      key: LoanType.AGRI,
      value: additionalDetails?.proofOfAgricultureUrl,
    },
    {
      label: "Salary ",
      key: LoanType.FAMILY_SUPPORT,
      value: additionalDetails?.salaryProofUrl,
    },
    {
      label: "Education",
      key: LoanType.EDUCATION_LOAN,
      value: additionalDetails?.identityCardUrl,
    },
  ];

  // eslint-disable-next-line no-sparse-arrays
  const documents = [
    {
      key: DocumentCategory.BORROWER,
      label: "Borrower Documents",
      value: (
        <Row gutter={[0, 30]}>
          {
            <Col span={8}>
              <CustomDocument
                label="Borrower Aaadhaar Front Side"
                src={customer?.aadhaarFrontImageUrl}
                lazyLoad
                thumbnailSrc={customer?.thumbnailDocs?.aadhaarThumb}
              />
            </Col>
          }
          <Col span={8}>
            <CustomDocument
              label="Borrower Aaadhaar Back Side"
              src={customer?.aadhaarBackImageUrl}
              lazyLoad
              thumbnailSrc={customer?.thumbnailDocs?.aadhaarThumb}
            />
          </Col>
          <Col span={8}>
            <CustomDocument
              label="Borrower PAN"
              src={customer?.panDocumentUrl}
              lazyLoad
              thumbnailSrc={customer?.thumbnailDocs?.panThumb}
            />
          </Col>

          {customer?.address?.addressAttachment?.map((url, index) => {
            return (
              <Col span={8}>
                <CustomDocument
                  label={`${`Address Proof ${index + 1}`}`}
                  src={url?.attachmentUrl}
                  lazyLoad
                  thumbnailSrc={customer?.thumbnailDocs?.houseImageThumb}
                />
              </Col>
            );
          })}
        </Row>
      ),
    },
    {
      key: DocumentCategory.CO_BORROWER,
      label: "Co-Borrower Documents",
      value: (
        <Row gutter={[0, 30]}>
          {
            <Col span={8}>
              <CustomDocument
                label="Co-Borrower Aaadhaar Front Side"
                src={coBorrower?.aadhaarFrontImageUrl}
                lazyLoad
                thumbnailSrc={customer?.thumbnailDocs?.aadhaarThumb}
              />
            </Col>
          }
          <Col span={8}>
            <CustomDocument
              label="Co-Borrower Aaadhaar Back Side"
              src={coBorrower?.aadhaarBackImageUrl}
              lazyLoad
              thumbnailSrc={customer?.thumbnailDocs?.aadhaarThumb}
            />
          </Col>
          <Col span={8}>
            <CustomDocument
              label="Co-Borrower PAN"
              src={coBorrower?.panDocumentUrl}
              lazyLoad
              thumbnailSrc={customer?.thumbnailDocs?.panThumb}
            />
          </Col>
          {coBorrower?.address?.addressAttachment?.map((url, index) => {
            return (
              <Col span={8}>
                <CustomDocument
                  label={`${`Address Proof ${index + 1}`}`}
                  src={url?.attachmentUrl}
                  lazyLoad
                  thumbnailSrc={customer?.thumbnailDocs?.houseImageThumb}
                />
              </Col>
            );
          })}
        </Row>
      ),
    },
    {
      key: DocumentCategory.NOMINEE,
      label: "Additional Insured Documents",
      value: (
        <Row gutter={[0, 30]}>
          {
            <Col span={8}>
              <CustomDocument
                label="Additional Insured Aaadhaar Front Side"
                src={nominee?.aadhaarFrontImageUrl}
                lazyLoad
                thumbnailSrc={customer?.thumbnailDocs?.aadhaarThumb}
              />
            </Col>
          }
          <Col span={8}>
            <CustomDocument
              label="Additional Insured Aaadhaar Back Side"
              src={nominee?.aadhaarBackImageUrl}
              lazyLoad
              thumbnailSrc={customer?.thumbnailDocs?.aadhaarThumb}
            />
          </Col>
          <Col span={8}>
            <CustomDocument
              label="Additional Insured PAN"
              src={nominee?.panDocumentUrl}
              lazyLoad
              thumbnailSrc={customer?.thumbnailDocs?.panThumb}
            />
          </Col>
          {nominee?.address?.addressAttachment?.map((url, index) => {
            return (
              <Col span={8}>
                <CustomDocument
                  label={`${`Address Proof ${index + 1}`}`}
                  src={url?.attachmentUrl}
                  lazyLoad
                  thumbnailSrc={customer?.thumbnailDocs?.houseImageThumb}
                />
              </Col>
            );
          })}
        </Row>
      ),
    },
    ,
    {
      key: DocumentCategory.BANK,
      label: "Bank Documents",
      value: (
        <Row gutter={[0, 30]}>
          <Col span={8}>
            <CustomDocument
              label="Bank Statement"
              src={customer?.bankDetails?.passbookOrChequeDocumentUrl}
              lazyLoad
              thumbnailSrc={customer?.thumbnailDocs?.bankStatementThumb}
            />
          </Col>
        </Row>
      ),
    },
    {
      key: DocumentCategory.LOAN_PROOF,
      label: `Proof of ${removeUnderscore(loanType ?? "")}`,
      value: (
        <Row>
          {loanType === LoanType.BUSINESS
            ? additionalDetails?.address?.addressAttachment?.map(
              (data, index) => (
                <Col span={8} key={data.id}>
                  <CustomDocument
                    key={data.attachmentId}
                    label={`Nature of ${removeUnderscore(
                      loanType ?? ""
                    )} Image ${index + 1}`}
                    src={data?.attachmentUrl}
                    lazyLoad
                    thumbnailSrc={customer?.thumbnailDocs?.houseImageThumb}
                  />
                </Col>
              )
            )
            : loanImages?.map(
              (data, index) =>
                loanType === data?.key && (
                  <CustomDocument
                    key={data.key}
                    label={`${data?.label} Document`}
                    src={data?.value}
                    lazyLoad
                    thumbnailSrc={getThumbnailByLabel(data?.label, customer?.thumbnailDocs)}
                  />
                )
            )}
        </Row>
      ),
    },
    {
      key: DocumentCategory.OTHER,
      label: "Documents",
      value: (
        <Row gutter={[0, 50]}>
          {filterModLoanDocuments(customer?.documents).map((doc, index) => (
            <React.Fragment key={doc.id}>
              {doc.frontSideImageUrl && (
                <Col span={8} className="mb-5" key={doc.id}>
                  <CustomDocument
                    label={`${doc?.getDocumentLabel()} Front Image`}
                    src={doc.frontSideImageUrl}
                    classname="mb-5"
                    lazyLoad
                    thumbnailSrc={getThumbnailByLabel(doc?.getDocumentLabel(), customer?.thumbnailDocs)}
                  />
                </Col>
              )}
              {doc.backSideImageUrl && (
                <Col span={8} className="mb-5">
                  <CustomDocument
                    label={`${doc?.getDocumentLabel()} Back Image`}
                    src={doc.backSideImageUrl}
                    lazyLoad
                    thumbnailSrc={getThumbnailByLabel(doc?.getDocumentLabel(), customer?.thumbnailDocs)}
                  />
                </Col>
              )}
            </React.Fragment>
          ))}
        </Row>
      ),
    },
    isModLoan ? {
      key: DocumentCategory.SECURITY,
      label: "Security Documents",
      value: (
        <Row className="Document-Upload">
          {customer?.documents && getModLoanDocuments(customer?.documents)
            .map((doc, key) => (
              <Col span={8} className="mb-5" key={doc.id}>
                <CustomDocument
                  key={doc.id}
                  label={doc.getDocumentLabel()}
                  src={doc.frontSideImageUrl}
                  classname="mb-5"
                  lazyLoad
                  thumbnailSrc={customer?.thumbnailDocs?.documentDefaultThumb}
                />
              </Col>
            ))
          }
          {isPending && 
          <Col span={8}>
            <div
              className="attachment-upload__input"
              onClick={() => setModal(true)}
            >
              <div className="attachment-upload__content">
                {" "}
                <img src={uploadIcon} alt="" className="" />
                <p> Upload Security Document</p>
              </div>
            </div>
            <Formik
              initialValues={new Loan()}
              onSubmit={handleUploadDocumentModal}
              validationSchema={modDocumentValidation}
            >
              {({ setFieldValue, isValid, dirty, values, resetForm }) => {
                const handleCancel = () => {
                  setModal(false)
                  resetForm();
                };

                return (
                  <div className="security-mod-form">
                    <CustomModal
                      className="mod-document-modal"
                      title="Upload Security Document"
                      visible={modal}
                      isCancelButton={false}
                      isOkButton={false}
                      onClose={() => setModal(false)}
                    >
                      <Form className="ant-form-vertical mt-5">
                        <Row gutter={[0, 30]}>
                          <Col span={24}>
                            <AttachmentUpload
                              accept="image/*,application/pdf"
                              name="frontSideImageId"
                              placeholder="Upload File"
                              setFieldValue={setFieldValue}
                              isLoading={(value) => setLoading(value)}
                            />
                            {values.frontSideImageId && !loading && (
                              <Col className="mt-5" span={8}>
                                <span className="text-primary mr-5">
                                  File Uploaded
                                </span>
                                <CloseCircleOutlined
                                  onClick={() =>
                                    setFieldValue("frontSideImageId", undefined)
                                  }
                                />
                              </Col>
                            )}
                          </Col>
                          <Col span={24}>
                            <div className="drawer-footer text-right">
                              <Button onClick={handleCancel}>Cancel</Button>
                              <Button
                                htmlType="submit"
                                disabled={!dirty || !isValid || loading}
                                loading={false}
                                type="link"
                                onClick={() => handleUploadDocumentModal}
                              >
                                Upload
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </CustomModal>
                  </div>
                );
              }}
            </Formik>
          </Col>
    }
        </Row>
      ),
    } : undefined,
  ];

  return (
    <>
      {!hasCard && (
        <Col span={24} className="block__heading mb-5">
          Documents
        </Col>
      )}
      {customer?.documents && customer?.documents?.length > 0 ? (
        <div className={hasCard ? "card" : "block"}>
          <div className="documents">
            <Row gutter={[60, 0]}>
              <Col span={6}>
                <div className="documents__wrapper">
                  {documents?.filter(val => val).map((val, index) => (
                    <Row
                      className="documents__wrapper-label cursor-pointer"
                      key={val?.key}
                      onClick={() =>
                        val?.key && handleDocumentVisibility(val?.key)
                      }
                    >
                      <Col span={20} className="text-primary">
                        <span
                          className={`${val?.key === currentDocument &&
                            `text-bold text-heading `
                            }`}
                        >
                          <span
                            onClick={() =>
                              val?.key && handleDocumentVisibility(val?.key)
                            }
                            className="cursor-pointer"
                          >
                            {val?.label}
                          </span>
                        </span>
                      </Col>
                      <Col span={4} className="text-right">
                        {val?.key === currentDocument ? (
                          <DownOutlined />
                        ) : (
                          <RightOutlined />
                        )}
                      </Col>
                    </Row>
                  ))}
                </div>
              </Col>

              <Col span={18}>
                {documents?.map(
                  (data) =>
                    data?.key === currentDocument && <div>{data?.value}</div>
                )}
              </Col>
            </Row>
          </div>
          <Modal
            visible={docModelOpen}
            cancelButtonProps={{ className: "modal-footer__cancel" }}
            onCancel={toggleDocModal}
            footer={false}
            bodyStyle={{ height: "500px" }}
            width="750px"
            className="documents-modal"
            destroyOnClose
          >
            {isImage(selectedFile) ? (
              <>
                <AppLoader loading={loading} />
                <img
                  alt="selected-file"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  src={selectedFile}
                  onLoad={() => setLoading(false)}
                />
              </>
            ) : (
              <iframe src={selectedFile} title="document" />
            )}
          </Modal>
        </div>
      ) : (
        <div className={hasCard ? "card" : "block"}>
          <div className="text-center text-danger">No document available</div>
        </div>
      )}
    </>
  );
};

export default Documents;
