import React, { useState } from "react";
import { Loan } from "../../../models/loan.model";
import { OptionArray } from "../../../shared/types/optionArray.type";
import { Button, Col, Input, Row } from "antd";
import "./acknowledgeDetails.scss";
import CustomDocument from "../../../shared/components/CustomDocument";
import documentIcon from "../../../Assets/images/docIcon.png";
import { Form, Formik, FormikHelpers } from "formik";
import InputField from "../../../shared/components/InputField";
import { InputType } from "../../../enums/input.type";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import fileUploadIcon from "../../../Assets/images/file-upload.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { acknowledgeFormValidation } from "../Pending/acknowledgeFormValidation";
import { CloseCircleOutlined } from "@ant-design/icons";
import { uploadReleaseFormValidation } from "../ToReturn/uploadReleaseValidation";
import getLocationStatus from "../../../shared/utils/compareDrawerUse";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import { ModService } from "../../../services/MODOfficer/modofficer.service";
import { DocumentCreate } from "../../../models/documentCreate.model";
import { DocumentType } from "../../../enums/documentType.type";
import { DocumentUpload } from "../../../models/fileAttachment.model";

interface AcknowledgeDrawerProps {
  document?: Loan;
  onClose: () => void;
  fetchDocuments: () => void;
}

const AcknowledgeDrawer = (props: AcknowledgeDrawerProps) => {
  const { document, onClose, fetchDocuments } = props;
  const {
    acknowledgeDocument,
    createDocument,
    updateDocumentLocation,
    toReturnMod,
  } = ModService();
  const location = useLocation();
  const { isPending, isReturned, isToReturn, isStored } = getLocationStatus(
    location.hash
  );
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const documentDetails: OptionArray[] = [
    {
      label: "Loan ID",
      value: fillEmptyData(document?.code),
    },
    {
      label: "Loan Amount",
      value: fillEmptyData(document?.requestedLoanAmt),
    },
    {
      label: "Branch",
      value: fillEmptyData(document?.branch?.name),
    },
    {
      label: "Customer ID",
      value: fillEmptyData(document?.customer?.code),
    },
    {
      label: "Customer Name",
      value: fillEmptyData(document?.customer?.getName()),
    },
  ];

  const handleSubmit = async (values: Loan) => {
    const update = await updateDocumentLocation({
      id: document?.id,
      modDocumentLocation: values.modDocumentLocation,
    });
    setTimeout(async () => {
      const data = await acknowledgeDocument({
        id: document?.id,
      });
    }, 1000);
    setTimeout(() => {
      fetchDocuments();
    }, 2000);
    onClose();
  };

  const handleUploadReleaseLetter = async (values: DocumentUpload) => {
    if (values?.frontSideImageId) {
      const response = await createDocument(
        {
          frontSideImageId: values.frontSideImageId,
          documentType: DocumentType.RELEASE_LETTER,
        },
        document?.id || 0
      );
      onClose();
    }
    setTimeout(async () => {
      const data = await toReturnMod({
        id: document?.id,
      });
    }, 1000);
    setTimeout(() => {
      fetchDocuments();
    }, 2000);
  };

  return (
    <div className="acknowledge-details-drawer">
      <div className="block__label">
        Generated Document ID:{" "}
        <span className="block__value">{document?.modDocumentId}</span>
      </div>
      <Row gutter={[16, 40]} className="document-details">
        {documentDetails?.map((data, id) => {
          return (
            <>
              <Col span={8} className="block__label" key={id}>
                <span> {data?.label}</span>
                <div className="block__value text-capitalize">
                  {data?.value}
                </div>
              </Col>
            </>
          );
        })}
      </Row>
      {document?.modDocumentUrl && (
        <Row>
          <div
            className="document-proof-container"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div className="text-container">
              <span>
                <img src={documentIcon} alt="" className="proof-icon ml-1" />
              </span>
              <CustomDocument
                label="MOD Document"
                src={document?.modDocumentUrl}
                onlyText={true}
                lazyLoad
              />
            </div>
          </div>
        </Row>
      )}
      {isPending && !isStored && (
        <Formik
          initialValues={new Loan()}
          onSubmit={handleSubmit}
          validationSchema={acknowledgeFormValidation}
        >
          {({ isValid, dirty, resetForm, values }) => {
            const handleClose = () => {
              onClose();
              resetForm();
            };
            return (
              <Form className="ant-form-vertical doc-location-form">
                <Col span={24}>
                  <InputField
                    label="Document Location"
                    type={InputType.TEXT}
                    name="modDocumentLocation"
                    placeholder="Enter Document Location"
                  />
                </Col>
                <div className="drawer-footer loan-filters__footer">
                  <Button
                    className="drawer-footer__cancel"
                    onClick={handleClose}
                    type="link"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="drawer-footer__submit"
                    htmlType="submit"
                    onClick={() => handleSubmit}
                    disabled={!isValid || !dirty}
                  >
                    Acknowledge
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
      {!isPending && !isToReturn && !isReturned && (
        <Col span={24} className="mt-5">
          <span className="block__value">Document Location</span>
          <Input
            value={document?.modDocumentLocation}
            readOnly
            className="doc-location-field"
          />
        </Col>
      )}
      {!isPending && !isStored && !isReturned && (
        <div className="mt-5">
          <Col className="block__value">Document Location</Col>
          <Col className="block__label">{document?.modDocumentLocation}</Col>
        </div>
      )}
      {!isPending && !isStored && !isToReturn && (
        <div>
          <div className="empty-space"></div>
          <Row>
            <Col span={12} className="block__value">
              Return Date
            </Col>
            <Col span={12} className="block__value">
              Release Letter
            </Col>
            <Col span={12} className="block__label">
              {document?.modReturnDate}
            </Col>
            <Col span={12} className="release-document">
              <div
                className="document-proof-container"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <div className="text-container">
                  <span>
                    <img
                      src={documentIcon}
                      alt=""
                      className="proof-icon ml-1"
                    />
                  </span>
                  <CustomDocument
                    label="Release Letter"
                    src={document?.releaseLetter}
                    onlyText={true}
                    lazyLoad
                    thumbnailSrc={document?.customer?.thumbnailDocs?.documentDefaultThumb}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
      {!isPending && !isStored && !isReturned && (
        <Row className="release-letter-upload__container">
          <Col span={24} className="block__value mb-5">
            Upload Release Letter
          </Col>
          <Col span={24}>
            {document?.releaseLetter && (
              <Col
                span={12}
                className="document-proof-container"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <div className="text-container">
                  <span>
                    <img
                      src={documentIcon}
                      alt=""
                      className="proof-icon ml-1"
                    />
                  </span>
                  <CustomDocument
                    label="Release Letter"
                    src={document?.releaseLetter}
                    onlyText={true}
                    lazyLoad
                  />
                </div>
              </Col>
            )}
          </Col>
        </Row>
      )}
      {!isPending && !isStored && !isReturned && (
        <Row>
          <Formik
            initialValues={new Loan()}
            onSubmit={handleUploadReleaseLetter}
            validationSchema={uploadReleaseFormValidation}
          >
            {({ isValid, dirty, resetForm, values, setFieldValue }) => {
              return (
                <Form className="ant-form-vertical doc-location-form">
                  <Col span={24}>
                    <AttachmentUpload
                      name="frontSideImageId"
                      placeholder="Upload"
                      accept="image/*, application/pdf"
                      customUploadIcon={fileUploadIcon}
                      setFieldValue={setFieldValue}
                      isLoading={(value) => setIsLoading(value)}
                    />
                    {values.frontSideImageId && !isLoading && (
                      <Col className="mt-5" span={24}>
                        <span className="text-primary mr-5 text-center">
                          File Uploaded
                        </span>
                        <CloseCircleOutlined
                          onClick={() =>
                            setFieldValue("frontSideImageId", undefined)
                          }
                        />
                      </Col>
                    )}
                  </Col>
                  <div className="drawer-footer loan-filters__footer">
                    <Button
                      className="drawer-footer__cancel"
                      onClick={() => onClose()}
                      type="link"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="drawer-footer__submit"
                      htmlType="submit"
                      disabled={!isValid || !dirty}
                      onClick={() => handleSubmit}
                    >
                      Confirm
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Row>
      )}
    </div>
  );
};

export default AcknowledgeDrawer;
