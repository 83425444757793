import { SidebarOptions } from "../../../enums/sidebarOptions.enum";

export const userAccesssOptions = [
  {
    label: "Dashboard",
    key: SidebarOptions.DASHBOARD,
  },
  {
    label: "CT Verification",
    key: SidebarOptions.CT_VERIFICATION,
  },
  {
    label: "User",
    key: SidebarOptions.USER,
  },
  {
    label: "Customer",
    key: SidebarOptions.CUSTOMER,
  },
  {
    label: "Pipeline",
    key: SidebarOptions.PIPELINE,
  },
  {
    label: "Loan",
    key: SidebarOptions.LOAN,
  },
  {
    label: "Repayments",
    key: SidebarOptions.REPAYMENTS,
  },
  {
    label: "Claims",
    key: SidebarOptions.CLAIMS,
  },
  {
    label: "Disbursement",
    key: SidebarOptions.DISBURSEMENT,
  },
  {
    label: "Configuration",
    key: SidebarOptions.CONFIGURATION,
  },
  {
    label: "Reports",
    key: SidebarOptions.REPORTS,
  },
  {
    label: "Write Access",
    key: SidebarOptions.WRITE_ACCESS,
  },
  {
    label: "Documents",
    key: SidebarOptions.DOCUMENTS
  }
];
