import { Form, Formik } from 'formik';
import React from 'react';
import InputField from '../../../../shared/components/InputField';
import { InputType } from '../../../../enums/input.type';
import { Button, Col, Row } from 'antd';
import '../customerDetail.scss';
import { VivitriBorrowerFields, VivitriCoBorrowerFields } from './constants';
import { CreditScore } from '../../../../models/creditScore.model';
import { VivitriValidationSchema } from './validation';

export interface VivitriFormProps {
    handleSubmit: (values: CreditScore) => void;
    toggleStatusVisibility?: () => void;
    loading?: boolean;
}

const SectionFields = ({ sectionTitle, fields }: { sectionTitle: string, fields: typeof VivitriBorrowerFields }) => (
    <>
        <Col className="text-primary" span={24}>
            <h2>{sectionTitle}</h2>
        </Col>
        {fields.map((field) => (
            <Col span={12} key={field.name}>
                <InputField
                    label={field.label}
                    name={field.name}
                    type={InputType.NUMBER}
                    placeholder={`Enter ${field.label}`}
                />
            </Col>
        ))}
    </>
);

const VivitriForm: React.FC<VivitriFormProps> = ({ handleSubmit, toggleStatusVisibility, loading }) => (
    <Formik
        validateOnChange
        validateOnBlur
        initialValues={new CreditScore()}
        onSubmit={handleSubmit}
        validationSchema={VivitriValidationSchema}
    >
        {({ dirty, isValid, errors }) => {

            return (
                <div>
                    <Form>
                        <Row gutter={20}>
                            <SectionFields sectionTitle="Borrower" fields={VivitriBorrowerFields} />
                            <SectionFields sectionTitle="Co Borrower" fields={VivitriCoBorrowerFields} />
                        </Row>
                        <div className="text-right mt-5">
                            <Button
                                htmlType="reset"
                                onClick={toggleStatusVisibility}
                                className="modal-footer__cancel"
                                type="link"
                            >
                                Cancel
                            </Button>
                            <Button
                                loading={loading}
                                className="modal-footer__submit w-10"
                                htmlType="submit"
                                disabled={!dirty || !isValid}
                            >
                                Confirm
                            </Button>
                        </div>
                    </Form>
                </div>
            );
        }}
    </Formik>
);

export default VivitriForm;
