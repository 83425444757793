export const VivitriBorrowerFields = [
    { label: 'Total Overdue Amount AOD', name: 'totalOverDueAmount' },
    { label: 'Max DPD Tradeline 3 Months', name: 'maxDpdTradeline3Months' },
    { label: 'Max DPD Tradeline 6 Months', name: 'maxDpdTradeline6Months' },
    { label: 'Max DPD Tradeline 12 Months', name: 'maxDpdTradeline12Months' },
    { label: 'Bureau Score', name: 'bureauScore' },
    { label: 'Risk Events', name: 'riskEvents' },
];

export const VivitriCoBorrowerFields = [
    { label: 'Total Overdue Amount AOD', name: 'cBTotalOverDueAmount' },
    { label: 'Max DPD Tradeline 3 Months', name: 'cbMaxDpdTradeline3Months' },
    { label: 'Max DPD Tradeline 6 Months', name: 'cbMaxDpdTradeline6Months' },
    { label: 'Max DPD Tradeline 12 Months', name: 'cbMaxDpdTradeline12Months' },
    { label: 'Bureau Score', name: 'cbBureauScore' },
    { label: 'Risk Events', name: 'cbRiskEvents' },
];

export const VivitriValidationMessages = {
    TOTAL_OVERDUE_AMOUNT: "Total overdue amount is required",
    MAX_DPD_TRADELINE_3_MONTHS: "Maximum DPD tradeline for three months is required",
    MAX_DPD_TRADELINE_6_MONTHS: "Maximum DPD tradeline for six months is required",
    MAX_DPD_TRADELINE_12_MONTHS: "Maximum DPD tradeline for twelve months is required",
    BUREAU_SCORE: "Bureau score is required",
    RISK_EVENTS: "Risk events are required",
    CB_TOTAL_OVERDUE_AMOUNT: "Co-borrower total overdue amount is required",
    CB_MAX_DPD_TRADELINE_3_MONTHS: "Co-borrower maximum DPD tradeline for three months is required",
    CB_MAX_DPD_TRADELINE_6_MONTHS: "Co-borrower maximum DPD tradeline for six months is required",
    CB_MAX_DPD_TRADELINE_12_MONTHS: "Co-borrower maximum DPD tradeline for twelve months is required",
    CB_BUREAU_SCORE: "Co-borrower bureau score is required",
    CB_RISK_EVENTS: "Co-borrower Risk Events are required",
  };