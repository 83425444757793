import * as Yup from "yup";
import { VivitriValidationMessages } from "./constants";

export const VivitriValidationSchema = Yup.object().shape({
  totalOverDueAmount: Yup.number().required(VivitriValidationMessages.TOTAL_OVERDUE_AMOUNT),

  maxDpdTradeline3Months: Yup.number().required(VivitriValidationMessages.MAX_DPD_TRADELINE_3_MONTHS),

  maxDpdTradeline6Months: Yup.number().required(VivitriValidationMessages.MAX_DPD_TRADELINE_6_MONTHS),

  maxDpdTradeline12Months: Yup.number().required(VivitriValidationMessages.MAX_DPD_TRADELINE_12_MONTHS),

  bureauScore: Yup.number().required(VivitriValidationMessages.BUREAU_SCORE),

  riskEvents: Yup.number().required(VivitriValidationMessages.RISK_EVENTS),

  cBTotalOverDueAmount: Yup.number().required(VivitriValidationMessages.CB_TOTAL_OVERDUE_AMOUNT),

  cbMaxDpdTradeline3Months: Yup.number().required(VivitriValidationMessages.CB_MAX_DPD_TRADELINE_3_MONTHS),

  cbMaxDpdTradeline6Months: Yup.number().required(VivitriValidationMessages.CB_MAX_DPD_TRADELINE_6_MONTHS),

  cbMaxDpdTradeline12Months: Yup.number().required(VivitriValidationMessages.CB_MAX_DPD_TRADELINE_12_MONTHS),

  cbBureauScore: Yup.number().required(VivitriValidationMessages.CB_BUREAU_SCORE),

  cbRiskEvents: Yup.number().required(VivitriValidationMessages.CB_RISK_EVENTS),
});
